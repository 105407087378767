<template>
  <van-pull-refresh v-model="isLoading" :head-height="80" @refresh="getDetail(0)" style="width: 100%;overflow-y: scroll;">
    <div class="overall">
      <div class="top-middle">
      <div class="top-content-father">
        <van-image :src="require('@/assets/img/detail-background.png')"/>
        <div class="detail-max-value">
          {{ maxValue }}<div class="score">分</div>
        </div>
      </div>
      <div class="content-middle">
          <div style="width: 100%">
            <van-list v-model="loading" :finished="finished" offset="0" finished-text="没有更多了" @load="getDetail(1)">
              <div v-for="record in recordList" class="list-item">
              <div class="item-content1">
                <div >{{record.reason}}</div>
                <div  style="color: #999999">总分{{record.sum}}</div>
              </div>
              <div class="item-content2">
                <div style="color: #999999">{{record.createDate}}</div>
                <div style="font-weight: bold">
                  <span v-if="record.type === 0">+</span>
                  <span v-if="record.type === 1">-</span>
                  {{record.num}}</div>
              </div>
            </div>
            </van-list>
          </div>
      </div>
    </div>
  </div>
  </van-pull-refresh>
</template>

<script>

import topBar from '@/components/topBar/topBar'

export default {
  name: "integral-detail",
  components :{
    topBar
  },
  data(){
    return{
      finished:false,
      loading:false,
      isLoading:false,
      recordList: [],
      maxValue: 0,
      page: 0,
      limit: 10,
    }
  },
  mounted() {
    this.getDetail(0)
    this.getMaxValue();
  },
  methods: {
    getMaxValue() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/user/account/countByUserId'),
        method: "post",
        params: this.$httpApp.adornParams({
          userId: this.$userId,
          community: this.$orgId,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.maxValue = data.count;
        } else {
          this.$notify({type: "danger", message: data.msg})
        }
      });
    },
    getDetail(type) {
      if(type === 0){
        setTimeout(() => {
          this.isLoading = false;
        }, 2000);
        this.page=0
        this.recordList = []
      }
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/user/account/flow/list'),
        method: "post",
        params: this.$httpApp.adornParams({
          userId: this.$userId,
          limit: this.limit,
          page: this.page++,
        })
      }).then(({ data }) => {
        console.log(data)
        if (data && data.code === 0) {
          data.page.list.forEach(item=>{
            this.recordList.push(item)
          })
          this.loading = false
          if(data.page.list.length < 10){
            this.finished = true
          }
        } else {
          this.$notify({type: "danger", message: data.msg})
        }
      });
    },
  },

}
</script>

<style scoped>
.overall {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.top-middle {
  overflow-y: scroll;
}
.top-content-father {
  position: relative;
  top: 0;
  width: 100vm;
  display: flex;
}
.content-middle {
  display: flex;
  flex: 1;
  overflow-y: scroll;
  margin-top: 0.2rem;
}
.detail-max-value {
  position: absolute;
  top:60%;
  left:50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: white;
  font-size: 45px;
}
.score {
  display: inline-block;
  font-size: 34px;
}
.list-item{
  height: 1.5rem;
  margin: 0 0.5rem;
  padding: 0.2rem 0;
  border-bottom: 1px solid rgb(243,243,243);
}
.item-content1{
  display: flex;
  justify-content: space-between;
  font-size: 0.35rem;
  line-height: 0.4rem;
  margin-top: 0.1rem;
}
.item-content2{
  display: flex;
  justify-content: space-between;
  font-size: 0.3rem;
  margin-top: 0.2rem ;

}
</style>
